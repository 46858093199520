<script lang="ts" setup>
const attrs = useAttrs()

const emit = defineEmits(['click'])
</script>

<template>
  <v-btn v-bind="attrs" variant="plain" class="text-btn" :ripple="false" @click="emit('click', $event)">
    <slot />
  </v-btn>
</template>

<style lang="sass" scoped>
.text-btn.v-btn
  padding: 0.25rem
  font-size: 1rem
  &:hover
    opacity: 0.62 !important
  :deep(.v-btn__content)
    opacity: 1 !important
    font-weight: 400
    display: flex
    justify-content: flex-start
    align-items: center
</style>
